<template>
  <b-modal id="edit-subject" size="lg" centered :title="title">
    <form id="edit-subject-form" @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <b-form-group label="Thai Subject">
            <b-form-input
              required
              v-model="form.subject.th"
              :state="!!form.subject.th"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-sm-12 col-lg-12">
          <b-form-group label="English Subject">
            <b-form-input
              required
              v-model="form.subject.en"
              :state="!!form.subject.en"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-sm-12 col-lg-12">
          <b-form-group label="Thai email body">
            <b-form-textarea
              required
              v-model="form.body.th"
              :state="!!form.body.th"
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col-sm-12 col-lg-12">
          <b-form-group label="English email body">
            <b-form-textarea
              required
              v-model="form.body.en"
              :state="!!form.body.en"
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col-sm-12 col-lg-12">
          <b-form-group label="Add receiver email">
            <multiselect
              v-model="form.email"
              tag-placeholder=""
              placeholder="Add receiver email"
              :options="emailOption"
              :multiple="true"
              :taggable="true"
              required
            ></multiselect>
          </b-form-group>
        </div>
      </div>
    </form>

    <template slot="modal-footer" slot-scope="{ cancel }">
      <b-button
        size="sm"
        variant="primary"
        type="submit"
        form="edit-subject-form"
        >Save</b-button
      >
      <b-button size="sm" variant="secondary" @click="cancel()"
        >Cancel</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

export default {
  props: {
    data: Object,
    onSave: Function
  },
  data: () => ({
    title: 'Edit Subject',
    form: {
      title: {
        th: '',
        en: ''
      },
      subject: {
        th: '',
        en: ''
      },
      body: {
        th: '',
        en: ''
      },
      email: [],
      status: 'Inactive'
    },
    emailOption: []
  }),
  methods: {
    ...mapActions({
      getEmailList: ActionFetch.CONFIG.EMAIL.LIST
    }),
    onCancel () {},
    onSubmit ($e) {
      $e.preventDefault()

      if (this.form.email.length > 0) {
        this.onSave(this.form)
        this.$bvModal.hide('edit-subject')
      } else {
        this.$bvToast.toast(`Please input email`, {
          title: 'Form Invalid',
          autoHideDelay: 2000,
          appendToast: true,
          variant: 'warning',
          toaster: 'b-toaster-bottom-right',
          solid: true
        })
      }
    },
    fetchEmailList () {
      this.getEmailList().then(($res) => {
        if ($res.success) {
          this.emailOption = $res.data.map(($email) => {
            // return {value: $email.email, name: $email.email}
            return $email.email
          })
        }
      })
    }
  },
  created () {
    this.fetchEmailList()
  },
  watch: {
    data: function (newVal, oldVal) {
      this.form = Object.assign({}, newVal)
    }
  }
}
</script>
