<template>
  <div id="config-subject-page">
    <div class="page-name">
      <div class="page-title">Set Subject & Reply Email</div>
    </div>
    <div class="page-container">
      <b-card class="w-100">
        <div class="table-responsive">
          <div class="text-left"></div>
          <div class="text-right mb-3">
            <h5 style="display: inline; float: left">
              Set Subject & Reply Email
            </h5>
            <b-button
              size="md"
              v-b-modal.edit-advance-search
              variant="primary"
              v-on:click="onAddNewList"
              >Add New List</b-button
            >
          </div>

          <table class="table">
            <thead>
              <tr>
                <th>Subject</th>
                <th>Receiver Email</th>
                <th>Last Update</th>
                <th>Published</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in subjectList" :key="index">
                <td>{{ item.subject.th }}</td>
                <td>{{ item.email.join(", ") }}</td>
                <td>{{ renderDate(item.updatedAt) }}</td>
                <td>
                  <toggle-button
                    :value="item.status.toLowerCase() === 'active'"
                    :labels="true"
                    @change="(e) => onSetActive(e, item)"
                  />
                </td>
                <td>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    class="btn-group-space"
                    @click="onOpenEdit(item)"
                    >Edit</b-button
                  >
                  <b-button
                    size="sm"
                    variant="outline-danger"
                    class="btn-group-space"
                    @click="onDelete(item)"
                    >Delete</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="table-pagination">
          <b-pagination
            v-model="paginate.page"
            :total-rows="paginate.totalRow"
            :per-page="paginate.limit"
            v-on:change="onUpdatePaginate"
          ></b-pagination>
          <b-form-select
            class="col-2 mx-2"
            v-model="paginate.limit"
            :options="perPageOptions"
            v-on:change="onUpdateLimit"
          ></b-form-select>
        </div>
      </b-card>
    </div>

    <SubjectModal :data="editData" :onSave="onSave" />
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapActions } from 'vuex'
import { ActionFetch } from '@/store/actionTypes'

import SubjectModal from '@/components/modals/Subject.modal.vue'

export default {
  components: {
    SubjectModal
  },
  data: () => ({
    subjectList: [],
    perPageOptions: [
      { value: 10, text: '10 / page' },
      { value: 20, text: '20 / page' },
      { value: 30, text: '30 / page' }
    ],
    paginate: {
      limit: 10,
      page: 1,
      totalPage: 1,
      totalRow: 0
    },
    editData: {}
  }),
  methods: {
    ...mapActions({
      getList: ActionFetch.CONFIG.SUBJECT.LIST,
      createSubject: ActionFetch.CONFIG.SUBJECT.CREATE,
      updateSubject: ActionFetch.CONFIG.SUBJECT.UPDATE,
      deleteSubject: ActionFetch.CONFIG.SUBJECT.DELETE
    }),
    renderDate (date) {
      return format(date, 'YYYY-MM-DD')
    },
    fetchSubject ($page = 1) {
      const condition = {
        orderBy: 'id',
        direction: 'desc',
        limit: this.paginate.limit,
        page: $page
      }
      this.getList(condition).then(($res) => {
        if ($res.success) {
          this.subjectList = $res.data

          this.paginate.totalPage = $res.dataLimit
            ? $res.dataLimit.totalPage
            : 1
          this.paginate.totalRow = $res.dataLimit
            ? $res.dataLimit.totalData
            : 0
        }
      })
    },
    openModal ($data) {
      this.editData = $data
      this.$bvModal.show('edit-subject')
    },
    onAddNewList () {
      const data = {
        title: {
          th: '',
          en: ''
        },
        subject: {
          th: '',
          en: ''
        },
        email: [],
        body: {
          th: '',
          en: ''
        },
        status: 'Inactive'
      }

      this.openModal(data)
    },
    onCreateSubject ($data) {
      this.createSubject($data).then(($res) => {
        if ($res.success) {
          this.fetchSubject(this.paginate.page)
        }
      })
    },
    onUpdateSubject ($data) {
      this.updateSubject($data).then(($res) => {
        if ($res.success) {
          this.fetchSubject(this.paginate.page)
        }
      })
    },
    onSave ($data) {
      if (!$data.id) {
        this.onCreateSubject($data)
      } else {
        this.onUpdateSubject($data)
      }
    },
    onUpdateLimit ($limit) {
      this.paginate.limit = $limit

      this.fetchSubject(this.paginate.page)
    },
    onUpdatePaginate ($paginate) {
      this.paginate.page = $paginate

      this.fetchSubject($paginate)
    },
    onOpenEdit ($data) {
      let data = Object.assign({}, $data)

      data.subject = { ...$data.subject }
      data.body = { ...$data.body }

      this.openModal(data)
    },
    onDelete ($data) {
      // this.deleteSubject($data.id).then(($res) => {
      //   if ($res.success) {
      //     this.fetchSubject(this.paginate.page)
      //   }
      // })

      this.$bvModal
        .msgBoxConfirm(`Are you sure ?`, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0 justify-center',
          centered: true,
          okOnly: true
        })
        .then(($conRes) => {
          if ($conRes) {
            this.deleteSubject($data.id).then(($res) => {
              if ($res.success) {
                this.fetchSubject(this.paginate.page)
              }
            })
          }
        })
    },
    onSetActive ($data, $subject) {
      $subject.status = $data.value ? 'Active' : 'Inactive'

      let payloadSubject = {
        id: $subject.id,
        status: $subject.status
      }

      this.onUpdateSubject(payloadSubject)
    }
  },
  created () {
    this.fetchSubject()
  }
}
</script>
